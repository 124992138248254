import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default function IndexPageEn(props) {
  const lang = props.pageContext.langKey

  return (
    <div>
      <Layout isHome lang={lang}>
        <Seo title="Home" />
        <div className="h-full w-full relative">
          <div className="w-full h-full">
            <div className="absolute h-full w-full ">
              <StaticImage
                alt="Background"
                src="../images/carbon-bg.jpg"
                objectFit="cover"
                className="h-full w-full"
                quality="100"
              />
            </div>
            <div className="relative h-full w-full flex flex-col flex-wrap items-center md:justify-center pt-16 lg:pt-0 z-50">
              <div className="container font-semibold intro-text text-xl md:text-2xl ">
                <div className="leading-relaxed">
                  <p>
                    The EU ETS market is changing dramatically. A new era as
                    started, with climate politics high up on the political
                    agenda and rapidly increasing carbon prices.
                  </p>
                  <p>
                    Bodecker Carbon – a part of Bodecker Partners – offers
                    independent advisory, analysis and portfolio management of
                    Emission Allowances and linked market instruments. With
                    unique expertise and experience, we have followed the carbon
                    market as leading analysts, risk managers and financial
                    traders since the start of EU ETS, and we have managed
                    carbon portfolios for both Utilities and Industries.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}
